<template>
  <v-form v-model="valid" ref="form">
    <v-layout row wrap justify-center>
      <v-flex lg12 sm12 xs12>
        <div class="d-flex">
          <v-textarea outlined v-model="comando" label="Comando" auto-grow :readonly="true"></v-textarea>
        </div>
        <div class="d-flex">
          <v-data-table
            :headers="headers"
            :items="tentativas"
            :rows-per-page-items="[10, 25, 50, { text: 'All', value: -1 }]"
            class="elevation-1"
          >
            <template v-slot:items="props">
              <td>{{ props.item.id }}</td>
              <td>{{ props.item.mensagem }}</td>
              <!-- <td>{{ props.item.detalhe }}</td> -->
              <td>{{ props.item.dataProcessamentoString }}</td>
            </template>
          </v-data-table>
        </div>
      </v-flex>
    </v-layout>
  </v-form>
</template>
<script>
import ServicoSincronizacao from "../../servicos/servicoSincronizacao"
const servicoSincronizacao = new ServicoSincronizacao()
export default {
  data() {
    return {
      comando: "",
      tentativas: [],
      headers: [
        { text: "Código", align: "center", value: "id", sortable: false },
        { text: "Mensagem", value: "mensagem", sortable: false },
        // { text: "Detalhe", value: "detalhe", sortable: false },
        { text: "Data e hora do processamento", value: "dataProcessamentoString", sortable: false }
      ]
    }
  },
  created() {
    if (this.$route && this.$route.params) {
      this.id = this.$route.params.id
    }
    if (this.id) {
      servicoSincronizacao.getTentativasByIdSincronizacao(this.id).then(res => {
        this.tentativas = res.data
        this.comando = res.data[0].comando
      })
    }
  },
  methods: {
    voltar() {
      this.$router.push({ name: "Sincronizacao" })
    }
  }
}
</script>
