<template>
  <div id="pageSincronizacao">
    <v-container grid-list-x2 fluid>
      <v-layout row wrap justify-center>
        <v-flex lg10 sm12 xs12>
          <v-card>
            <v-toolbar color="pink" dark flat dense cad>
              <v-toolbar-title class="subheading">Sincronização {{ this.id }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <div class="form-btn">
                <v-btn @click="voltar" outline>Voltar</v-btn>
              </div>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text class="">
              <tentativas-table></tentativas-table>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import TentativasTable from "@/components/sincronizacao/tableTentativas"
export default {
  components: {
    TentativasTable
  },
  data: () => ({
    id: null
  }),
  created() {
    if (this.$route && this.$route.params) {
      this.id = this.$route.params.id
    }
  },
  methods: {
    voltar() {
      this.$router.push({ name: "Sincronizacao" })
    }
  }
}
</script>
